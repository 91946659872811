<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-tabs
      v-model="currentTabIndex"
      active-nav-item-class="font-weight-bold text-uppercase text-success"
      class="mt-3"
    >
      <b-tab>
        <template #title>
          <i :class="iconState('ri-file-edit', currentTabIndex === 0)" /> Pendings
        </template>
        <PostingPending
          ref="spPending"
          :tab-index="currentTabIndex"
          @show="showPosting"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <i :class="iconState('ri-file', currentTabIndex === 1)" /> Unposted
        </template>
        <Unposted
          ref="spManual"
          :tab-index="currentTabIndex"
          @show="showPosting"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <i :class="iconState('ri-file-history', currentTabIndex === 2)" /> Histories
        </template>
        <PostingHistory
          ref="saHistory"
          :tab-index="currentTabIndex"
          @show="showPosting"
        />
      </b-tab>
    </b-tabs>

    <b-modal
      id="modalPosting"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      content-class="full-height"
      modal-class="full-height"
      aria-hidden="false"
    >
      <b-tabs
        v-model="state.currentTabIndex"
        active-nav-item-class="font-weight-bold text-uppercase text-success"
        content-class="scrollable"
        nav-class="d-flex flex-row justify-content-start"
      >
        <b-tab
          class="p-2"
          title-item-class="justify-content-start"
        >
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-stack-overflow-line h4" />
              <b class="is-tab-active">Products</b>
            </div>
          </template>
          <b-row class="mx-0 mt-1 mb-0">
            <b-col
              cols="12"
              class="px-1 px-md-2"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="PO Number"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.references.purchase_number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Document Number"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.references.document_number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Transfer Number"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.references.transfer_number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="SAP Entry"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.references.sap_doc_entry"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Requested By"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.headers.destination_name"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Group"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.headers.group_name"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Remarks"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.remarks || 'no remarks'"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="px-0 px-md-1"
            >
              <ValidationObserver
                ref="formProducts"
              >
                <b-table
                  ref="tableProducts"
                  small
                  hover
                  striped
                  bordered
                  responsive
                  show-empty
                  class="mb-0"
                  :items="stockRequest.details"
                  :busy="tableProducts.busy"
                  :filter="tableProducts.filter"
                  :fields="([0, 1].includes(currentTabIndex) ? tableProducts.fields : tableProducts.histories)"
                  :stacked="tableProducts.stacked"
                  :filter-included-fields="tableProducts.filterOn"
                >
                  <template #cell(index)="{ index }">
                    <div
                      class="text-md-nowrap"
                      v-text="tableRowNumber(tableProducts, index)"
                    />
                  </template>

                  <template #cell()="{ value }">
                    <div
                      class="text-md-nowrap d-flex align-items-center min-h-35px px-1"
                      v-text="value"
                    />
                  </template>

                  <template #cell(product_name)="{ value }">
                    <div class="text-md-nowrap d-flex align-items-center min-h-35px px-1">
                      <strong
                        class="font-weight-bolder"
                        v-text="value"
                      />
                    </div>
                  </template>

                  <template #cell(quantity)="{ value }">
                    <div class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px">
                      <strong
                        class="font-weight-bolder"
                        v-text="numberFormatter(value, '0,0')"
                      />
                    </div>
                  </template>

                  <template #cell(approved_quantity)="{ value }">
                    <div class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px">
                      <strong
                        class="font-weight-bolder"
                        v-text="numberFormatter(value, '0,0')"
                      />
                    </div>
                  </template>

                  <template #cell(posting_quantity)="{ item, index }">
                    <div
                      v-if="state.posting && Number(currentTabIndex) === 0 && Number(stockRequest.stage) <= 3"
                      class="text-md-nowrap"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        :vid="`details.${index}.posting_quantity`"
                        :rules="{
                          required: true,
                          min_value: state.postLater ? Number(item.approved_quantity) : 1,
                          max_value: Number(item.approved_quantity)
                        }"
                        name="quantity"
                      >
                        <b-form-group
                          :label-for="`fApprovedQuantity-${index}`"
                          class="mb-0"
                        >
                          <b-input
                            :id="`fApprovedQuantity-${index}`"
                            v-model="item.posting_quantity"
                            :state="(errors.length > 0 ? false : null)"
                            :max="item.approved_quantity"
                            placeholder="enter posting quantity here"
                            class="text-center"
                            type="number"
                            step="1"
                            min="0"
                          />
                          <div
                            v-if="(errors.length > 0)"
                            class="invalid-feedback"
                            v-text="errors[0]"
                          />
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                    <div
                      v-else
                      class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px"
                    >
                      <strong
                        :class="['font-weight-bolder', {
                          'text-danger': Number(item.posting_quantity) <= 0,
                          'text-warning': Number(item.posting_quantity) >= 1 && Number(item.approved_quantity) !== Number(item.posting_quantity),
                          'text-success': Number(item.approved_quantity) === Number(item.posting_quantity)
                        }]"
                        v-text="numberFormatter(item.posting_quantity, '0,0')"
                      />
                    </div>
                  </template>

                  <template #table-busy>
                    <div class="text-center py-5">
                      <b-icon
                        icon="stopwatch"
                        font-scale="5"
                        animation="cylon"
                      />
                      <p class="h3 py-2">
                        <strong>Loading . . .</strong>
                      </p>
                    </div>
                  </template>

                </b-table>
              </ValidationObserver>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-todo-line h4" />
              <b class="is-tab-active">Delivery Details</b>
            </div>
          </template>
          <b-row class="mx-0 mt-1 mb-0">
            <b-col
              cols="12"
              class="px-1 px-md-2"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label-for="fCustomerDirect"
                    class="mb-1"
                  >
                    <b-form-checkbox
                      id="fCustomerDirect"
                      v-model="stockRequest.delivery_details.customer_direct"
                      switch
                      value="1"
                      name="customer_direct"
                      unchecked-value="0"
                      disabled
                    >
                      <strong>Direct Deliver to Customer?</strong>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Mobile Number"
                    label-for="fvCustomerMobile"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvCustomerMobile"
                      v-model="stockRequest.delivery_details.mobile_number"
                      type="text"
                      placeholder="no customer mobile"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="9"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Name"
                    label-for="fvCustomerName"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvCustomerName"
                      v-model="stockRequest.delivery_details.customer"
                      type="text"
                      placeholder="no customer name"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Address"
                    label-for="fvCustomerAddress"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-textarea
                      id="fvCustomerAddress"
                      v-model="stockRequest.delivery_details.address"
                      rows="4"
                      max-rows="6"
                      maxlength="50"
                      placeholder="no customer address"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab
          v-if="[1, 2].includes(currentTabIndex)"
          class="p-2"
        >
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-calendar-line h4" />
              <b class="is-tab-active ml-1">Delivery Schedule</b>
            </div>
          </template>
          <b-row class="mx-0 mt-1 mb-0">
            <b-col
              cols="12"
              class="px-1 px-md-2"
            >
              <b-row>

                <b-col
                  cols="12"
                  md="4"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Delivery Date"
                    label-for="fvScheduleDate"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleDate"
                      v-model="stockRequest.schedule_details.date"
                      type="date"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="5"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Driver"
                    label-for="fvScheduleDriver"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleDriver"
                      v-model="stockRequest.schedule_details.driver_name"
                      type="text"
                      maxlength="50"
                      placeholder="no driver"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Mobile Number"
                    label-for="fvScheduleMobile"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleMobile"
                      v-model="stockRequest.schedule_details.mobile_number"
                      type="text"
                      maxlength="50"
                      placeholder="no mobile number"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                  class="px-1 px-md-2"
                />

                <b-col
                  cols="12"
                  md="8"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Helper"
                    label-for="fvScheduleHelper"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleHelper"
                      v-model="stockRequest.schedule_details.helper"
                      type="text"
                      maxlength="50"
                      placeholder="no helper"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Vehicle Plate"
                    label-for="fvScheduleVehiclePlate"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleVehiclePlate"
                      v-model="stockRequest.schedule_details.vehicle_plate"
                      type="text"
                      maxlength="25"
                      placeholder="no vehicle plate"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="8"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Vehicle Name"
                    label-for="fvScheduleVehicleName"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleVehicleName"
                      v-model="stockRequest.schedule_details.vehicle_name"
                      type="text"
                      maxlength="50"
                      placeholder="no helper"
                      disabled
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-history-line h4" />
              <b class="is-tab-active ml-1">Tracking</b>
            </div>
          </template>

          <Timeline
            :stock-request="stockRequest"
          >
            <template #approvals="request">
              <Signatory
                :stock-request="request"
                :approver="requesterSignatory"
              />
              <Signatory
                v-for="(approver, key) in request.approvals"
                :key="key"
                :stock-request="request"
                :approver="approver"
              />
            </template>
          </Timeline>
        </b-tab>

        <b-tab
          :disabled="Number(stockRequest.stage) <= 3"
          title-item-class="align-content-end align-self-end"
        >
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-printer-line h4" />
              <b class="is-tab-active ml-1">Printing</b>
            </div>
          </template>
          <iframe
            ref="ipage"
            :src="printing.output"
            height="100%"
            width="100%"
            class="scrollable-sm"
            hide-toolbar
          />
        </b-tab>

      </b-tabs>
      <template #modal-footer="{ cancel }">
        <div class="flex-grow-1 d-flex justify-content-between mb-0 pb-0">
          <div>
            <b-button
              v-if="Number(stockRequest.stage) <= 3 && state.posting && Number(stockRequest.posted) === 0"
              :disabled="state.busy"
              variant="outline-danger"
              @click.prevent="$event => validatePosting($event, 0)"
            >
              Post Later
            </b-button>
          </div>
          <div class="d-flex">
            <b-button
              v-if="Number(stockRequest.stage) >= 4"
              :disabled="state.busy"
              variant="secondary"
              class="mr-2"
              @click.prevent="$event => pdfPrint(true)"
            >
              Print
            </b-button>
            <div
              v-if="state.posting && Number(stockRequest.posted) === 0"
            >
              <b-button
                v-if="Number(currentTabIndex) === 0 && Number(stockRequest.stage) <= 3"
                :disabled="state.busy"
                variant="primary"
                class="mr-2"
                @click.prevent="$event => validatePosting($event, 1)"
              >
                Post
              </b-button>
              <b-button
                v-else-if="Number(currentTabIndex) === 1 && Number(stockRequest.stage) >= 4"
                :disabled="state.busy"
                variant="primary"
                class="mr-2"
                @click.prevent="$event => validateUnposted($event)"
              >
                Post
              </b-button>
            </div>
            <b-button
              variant="outline-dark"
              :disabled="state.busy"
              @click.prevent="$event => closePosting(cancel)"
            >
              Close <span class="d-none d-md-inline-block">Window</span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
// import FUSE from 'fuse.js'
import MISC from '@/mixins/misc'
import DOCUMENT_PRINTING from '@/mixins/printing/document'
import FORMATTER from '@/mixins/formatter'
import { core } from '@/config/pluginInit'

import PostingPending from '@/views/user/posting/Pending'
import Unposted from '@/views/user/posting/Unposted'
import PostingHistory from '@/views/user/posting/History'
import Timeline from '@/components/custom/timeline/Base'
import Signatory from '@/components/custom/timeline/Signatory'

import print from 'vue-print-nb'

export default {

  name: 'UserPostingBase',

  middleware: ['auth', 'user'],

  metaInfo: () => ({
    title: 'Posting'
  }),

  directives: {
    print
  },

  components: {
    PostingPending,
    Unposted,
    PostingHistory,
    Timeline,
    Signatory
  },

  mixins: [
    MISC,
    FORMATTER,
    DOCUMENT_PRINTING
  ],

  data () {
    return {
      currentTabIndex: this.getCurrentTabIndexByURL(),
      printing: {
        printed: false,
        output: null
      },
      state: {
        busy: false,
        posting: true,
        currentTabIndex: 0,
        postLater: false
      },
      stockRequest: {
        id: null,
        stage: 0,
        posted: 0,
        references: {
          document_number: null,
          transfer_number: null,
          sap_doc_entry: null,
          purchase_number: null
        },
        headers: {
          destination: null,
          destination_name: null,
          destination_code: null,
          origin: null,
          origin_name: null,
          origin_code: null,
          group: null,
          group_name: null,
          group_code: null
        },
        delivery_details: {
          customer_direct: 0,
          customer: null,
          address: null,
          mobile_number: null
        },
        schedule_details: {
          date: null,
          vehicle: null,
          vehicle_plate: null,
          vehicle_name: null,
          driver: null,
          driver_name: null,
          mobile_number: null,
          helper: null
        },
        details: [],
        approvals: [],
        remarks: null
      },
      requesterSignatory: {
        stage: 0,
        approver_label: 'Requester',
        approver_action: 'Requisition',
        approver_name: null,
        last_seen: null,
        status: 'Requested',
        status_at: null,
        remarks: null
      },
      tableProducts: {
        busy: false,
        filter: {
          search: null
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'product_name', class: 'min-w-200px' },
          { mobile: 2, key: 'quantity', class: ['text-center', { 'col-2': !this.isMobile() }] },
          { mobile: 3, key: 'approved_quantity', label: 'Approved', class: ['text-center', { 'col-2': !this.isMobile() }] },
          { mobile: 4, key: 'posting_quantity', label: 'Posting', class: ['text-center', { 'col-2': !this.isMobile() }] }
        ].sort(this.scaleOnMobile),
        histories: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'product_name', class: 'min-w-200px' },
          { mobile: 2, key: 'quantity', class: ['text-center', { 'col-2': !this.isMobile() }] },
          { mobile: 3, key: 'approved_quantity', label: 'Approved', class: ['text-center', { 'col-2': !this.isMobile() }] },
          { mobile: 4, key: 'posting_quantity', label: 'Posted', class: ['text-center', { 'col-2': !this.isMobile() }] }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    getCurrentTabIndexByURL () {
      switch (this.$route.params.poster) {
        case 'histories' : {
          return 2
        }
        case 'unposted' : {
          return 1
        }
        default: {
          return 0
        }
      }
    },

    showPosting (stockRequest, usePosting) {
      this.state.posting = usePosting
      this.state.postLater = false

      const { id, approvable } = stockRequest
      const { delivery_details, schedule_details, transfer_details } = approvable

      this.stockRequest = Object.assign(this.stockRequest, {
        id: id,
        stage: approvable.stage,
        posted: approvable.posted,
        references: {
          document_number: approvable.document_number,
          transfer_number: approvable.transfer_number,
          sap_doc_entry: approvable.sap_doc_entry,
          purchase_number: approvable.purchase_number
        },
        headers: {
          destination: approvable.destination_id,
          destination_name: approvable.destination_name,
          destination_code: approvable.destination_code,
          origin: approvable.origin_id,
          origin_code: approvable.origin_code,
          origin_name: approvable.origin_name,
          group: approvable.group_id,
          group_code: approvable.group_code,
          group_name: approvable.group_name
        },
        delivery_details: {
          customer_direct: Number(approvable.customer_direct),
          customer: delivery_details.customer,
          mobile_number: delivery_details.mobile_number,
          address: delivery_details.address
        },
        schedule_details: {
          date: schedule_details?.date || null,
          vehicle: schedule_details?.vehicle || null,
          vehicle_plate: schedule_details?.vehicle_plate || null,
          vehicle_name: schedule_details?.vehicle_name || null,
          driver: schedule_details?.driver || null,
          driver_name: schedule_details?.driver_name || null,
          mobile_number: schedule_details?.mobile_number || null,
          helper: schedule_details?.helper || null
        },
        details: (
          usePosting ? transfer_details.map(
            product => Object.assign(product, {
              posting_quantity: product.approved_quantity
            })
          ) : approvable.transfer_details
        ),
        approvals: approvable.approvals,
        remarks: approvable.remarks,
        created_at: approvable.created_at
      })

      this.requesterSignatory = Object.assign(this.requesterSignatory, {
        approver_name: approvable.destination_name,
        status_at: approvable.created_at
      })

      this.$bvModal.show('modalPosting')

      if (!usePosting || Number(this.currentTabIndex) === 1) {
        this.$nextTick(() => {
          this.pdfPrint(false)
        })
      }
    },

    closePosting (close) {
      if (Number(this.stockRequest.stage) >= 4) {
        if (this.currentTabIndex === 0) {
          this.$refs.spPending.tableRefresh()
        }

        if (this.currentTabIndex === 1) {
          this.$refs.spManual.tableRefresh()
        }
      }

      close()
    },

    pdfPrint (print = true) {
      const { references, headers, remarks } = this.stockRequest
      const rePrinted = [1, 2].includes(this.currentTabIndex)

      return this.previewVoucher({
        document_number: references.document_number,
        transfer_number: references.transfer_number,
        sap_doc_entry: references.sap_doc_entry,
        purchase_number: references.purchase_number,
        destination_name: headers.destination_name,
        origin_name: headers.origin_name,
        remarks: remarks,
        delivery: this.stockRequest.delivery_details,
        schedule: this.stockRequest.schedule_details,
        products: this.stockRequest.details,
        approvals: this.stockRequest.approvals
      }, rePrinted).then(
        async pdfOutput => {
          if (print) {
            return pdfOutput.print()
          }

          this.printing.output = null
          return new Promise(resolve => {
            pdfOutput.getDataUrl(url => {
              this.printing.output = `${url}#toolbar=0`
              resolve(url)
            })
          }).finally(() => {
            this.state.currentTabIndex = Number(this.currentTabIndex) >= 1 ? 4 : 3
          })
        }
      )
    },

    validatePosting ({ target }, sapPosting = 1) {
      this.state.postLater = sapPosting === 0

      this.$nextTick(() => {
        this.$refs.formProducts.validate().then(
          validPosting => {
            if (!validPosting) {
              return this.swalInvalid()
            }

            this.swalConfirmWithRemarks({
              html: `${target.innerText} to SAP?`,
              remarksRequired: true,
              customClass: {
                confirmButton: (
                  sapPosting === 0 ? (
                    'btn btn-sm btn-danger'
                  ) : 'btn btn-sm btn-success'
                )
              },
              preConfirm: remarks => {
                return this.$refs.spPending.update({
                  id: this.stockRequest.id,
                  details: this.stockRequest.details,
                  sap_posting: sapPosting,
                  remarks: remarks,
                  status: 'Posted'
                }).then(({ transfer }) => {
                  this.stockRequest = Object.assign(this.stockRequest, {
                    references: {
                      document_number: transfer.document_number,
                      transfer_number: transfer.transfer_number,
                      sap_doc_entry: transfer.sap_doc_entry,
                      purchase_number: transfer.purchase_number
                    },
                    stage: transfer.stage,
                    posted: transfer.posted
                  })

                  this.$nextTick(() => {
                    this.pdfPrint(false)
                  })
                })
              },
              confirmButtonText: target.innerText
            })
          }
        )
      })
    },

    validateUnposted ({ target }) {
      this.$refs.formProducts.validate().then(
        validPosting => {
          if (!validPosting) {
            return this.swalInvalid()
          }

          this.swalConfirmWithRemarks({
            html: `${target.innerText} this Purchase Order to SAP?`,
            remarksRequired: true,
            preConfirm: remarks => {
              return this.$refs.spManual.update({
                id: this.stockRequest.id,
                remarks: remarks,
                status: 'Posted'
              }).then(({ transfer }) => {
                this.stockRequest = Object.assign(this.stockRequest, {
                  references: {
                    document_number: transfer.document_number,
                    transfer_number: transfer.transfer_number,
                    sap_doc_entry: transfer.sap_doc_entry,
                    purchase_number: transfer.purchase_number
                  },
                  stage: transfer.stage,
                  posted: transfer.posted
                })

                this.$nextTick(() => {
                  this.pdfPrint(false)
                })
              })
            },
            confirmButtonText: target.innerText
          })
        }
      )
    }

  }
}
</script>
