import pdf from '@/mixins/pdf'
import formatter from '@/mixins/formatter'
import { PageSizes } from 'pdf-lib'

export default {

  mixins: [pdf, formatter],

  data: () => ({
    pdfVoucher: {
      remarks: null,
      destination_name: null,
      origin_name: null,
      delivery: null,
      schedule: null,
      purchase_number: null,
      transfer_number: null,
      sap_doc_entry: null,
      document_number: null,
      products: [],
      approvals: []
    },
    rePrinted: false,
    layouts: {
      outsideBorderOnly: {
        hLineWidth: function (i, node) {
          return (i === 0 || i === node.table.body.length) ? 1 : 0 // Outside border width
        },
        vLineWidth: function (i, node) {
          return (i === 0 || i === node.table.widths.length) ? 1 : 0 // Outside border width
        },
        hLineColor: function () {
          return 'black' // Outside border color
        },
        vLineColor: function () {
          return 'black' // Outside border color
        }
      },
      borderSize: (size = 1) => ({
        hLineWidth: () => size,
        vLineWidth: () => size,
        hLineColor: () => 'black',
        vLineColor: () => 'black'
      }),
      recievedBy: (size = [1, 1], padding = [0, 0.5]) => ({
        hLineWidth: () => Array.isArray(size) ? size[0] : size,
        vLineWidth: () => Array.isArray(size) ? size[1] : size,
        paddingLeft: () => Array.isArray(padding) ? padding[0] : padding,
        paddingRight: () => Array.isArray(padding) ? padding[1] : padding,
        hLineColor: () => 'black',
        vLineColor: () => 'black'
      })
    }
  }),

  methods: {

    PDFLine (color = 'black', width = 25) {
      return {
        canvas: [{
          type: 'line',
          x1: 0,
          y1: 1,
          x2: (PageSizes.Letter[0] - width),
          y2: 1,
          lineHeight: 0.5,
          lineWidth: 0.5,
          color: color
        }]
      }
    },

    PDFRow (firstColumn = [], secondColumn = [], firstWidth = '65%', secondWidth = '35%') {
      return {
        width: '*',
        margin: [0, 0],
        columns: [
          {
            width: firstWidth,
            stack: firstColumn
          },
          {
            width: secondWidth,
            stack: secondColumn
          }
        ]
      }
    },

    previewVoucher (
      pdfVoucher = {
        remarks: null,
        destination_name: null,
        origin_name: null,
        delivery: null,
        schedule: null,
        purchase_number: null,
        transfer_number: null,
        sap_doc_entry: null,
        document_number: null,
        products: [],
        approvals: []
      },
      rePrinted = false
    ) {
      this.pdfVoucher = pdfVoucher
      this.rePrinted = rePrinted
      const rePrintedBy = this.$store.getters['auth/user'].name || ' '

      return this.pdfMake({
        header: this.PDFVoucherHeader(),
        content: [
          this.PDFVoucherHeaders(),
          this.PDFRow(
            [this.PDFVoucherDetails()],
            [this.PDFVoucherRemarks()],
            '59.3%',
            '40.7%'
          ),
          this.PDFVoucherSignatories(),
          this.PDFVoucherReceivedBy()
        ],
        footer: (currentPage, pageCount) => ({
          margin: [20, 1, 27, 1],
          alignment: 'left',
          italics: true,
          fontSize: 10,
          bold: false,
          stack: [
            // {
            //   text: [
            //     'This is an electronically generated form. ',
            //     'No handwritten signature is needed on prepared and approved by.'
            //   ]
            // },
            {
              columns: [
                {
                  alignment: 'left',
                  width: '70%',
                  text: rePrinted ? `Reprinted by ${rePrintedBy} on ${this.dateTimeShortFormatter(Date.now())?.toUpperCase()}.` : ' '
                },
                {
                  alignment: 'right',
                  bold: true,
                  width: '30%',
                  text: `Page ${currentPage} of ${pageCount}`
                }
              ]
            }
          ]
        })
      },
      {
        pageSize: {
          height: PageSizes.Letter[1] / 2,
          width: PageSizes.Letter[0]
        },
        pageMargins: [15, 76, 25, 28],
        pageOrientation: 'landscape',
        fontSize: 11
      },
      {
        title: 'Purchase Orders',
        author: 'Inventory Transfer Management System',
        creator: 'Inventory Transfer Management System',
        subject: ''
      })
    },

    PDFVoucherHeader () {
      return [{
        margin: [15, 20, 25, 0],
        columns: [{
          layout: this.layouts.borderSize(0.5),
          table: {
            widths: ['60.5%', '19.5%', '20%'],
            body: [
              [
                {
                  text: 'ALJAY AGRO-INDUSTRIAL SOLUTIONS, INC.',
                  // [l, t, r, b]
                  border: [false, false, false, false],
                  alignment: 'left',
                  bold: true
                },
                {
                  text: 'Transfer No.',
                  border: [false, false, false, false],
                  // alignment: 'right',
                  bold: true
                },
                {
                  text: this.pdfVoucher.transfer_number,
                  border: [false, false, false, false],
                  alignment: 'right',
                  fontSize: 13,
                  bold: false
                }
              ],
              [
                {
                  text: 'Batal, Santiago City, Isabela, Philippines, 3311',
                  border: [false, false, false, false],
                  alignment: 'left',
                  bold: false
                },
                {
                  text: 'Reference No.',
                  border: [false, false, false, false],
                  bold: true
                },
                {
                  text: this.pdfVoucher.sap_doc_entry,
                  border: [false, false, false, false],
                  alignment: 'right',
                  bold: false
                }
              ],
              [
                {
                  text: 'Inventory Transfer Order',
                  border: [false, false, false, false],
                  alignment: 'left',
                  bold: false
                },
                {
                  // text: 'Document No.',
                  text: ' ',
                  border: [false, false, false, false],
                  bold: true
                },
                {
                  // text: this.pdfVoucher.document_number,
                  text: ' ',
                  border: [false, false, false, false],
                  alignment: 'right',
                  bold: false
                }
              ]
            ]
          }
        }]
      }]
    },

    PDFVoucherHeaders () {
      return {
        margin: [0, 0, 0, 0],
        layout: this.layouts.borderSize(0.5),
        table: {
          headerRows: 1,
          widths: ['7%', '53%', '10%', '30%'],
          body: [
            [
              {
                text: 'To:',
                border: [false, false, false, false],
                bold: true
              },
              {
                text: this.pdfVoucher.destination_name,
                border: [false, false, false, false],
                alignment: 'left',
                bold: false
              },
              {
                text: 'PO No.:',
                border: [false, false, false, false],
                bold: true
              },
              {
                text: this.pdfVoucher.purchase_number,
                border: [false, false, false, false],
                alignment: 'right',
                bold: false
              }
            ],
            [
              {
                text: 'From:',
                border: [false, false, false, true],
                bold: true
              },
              {
                text: this.pdfVoucher.origin_name,
                border: [false, false, false, true],
                alignment: 'left',
                bold: false
              },
              {
                text: 'Date:',
                border: [false, false, false, true],
                bold: true
              },
              {
                text: this.dateTimeShortFormatter(Date.now())?.toUpperCase(),
                border: [false, false, false, true],
                alignment: 'right',
                bold: false
              }
            ]
          ]
        }
      }
    },

    PDFVoucherDetails () {
      const products = Array.from(this.pdfVoucher.products)

      const totalProducts = products.length

      const additionalPages = products.map(
        (product, productIndex) => {
          const count = Number(productIndex) + 1

          let doPageBreak = false

          if (totalProducts <= 12) {
            if (count >= 9 && totalProducts === count) {
              doPageBreak = true
            }
          } else {
            const perPage = 14
            const otherPages = count - 12
            const maxItem = perPage - (otherPages % perPage)

            if (count === totalProducts) {
              if (maxItem >= 1 && totalProducts === count) {
                doPageBreak = true
              }
            } else {
              if ((otherPages % perPage) === 0) {
                doPageBreak = true
              }
            }
          }

          return [
            {
              text: count <= 9 ? `0${count}` : count,
              border: [false, false, false, false],
              margin: [1, 1, 0, 0],
              alignment: 'left',
              bold: false,
              ...doPageBreak ? {
                pageBreak: 'after'
              } : {}
            },
            {
              text: product.posting_quantity,
              border: [false, false, false, false],
              margin: [1, 1, 0, 0],
              alignment: 'left',
              bold: false
            },
            {
              text: 'PC',
              border: [false, false, false, false],
              margin: [4, 1, 0, 0],
              alignment: 'left',
              bold: false
            },
            {
              text: product.product_name,
              border: [false, false, false, false],
              margin: [1, 1, 0, 0],
              alignment: 'left',
              bold: false
            }
          ]
        }
      )

      return {
        margin: [0, 2, 0, 0],
        layout: this.layouts.borderSize(0.5),
        table: {
          headerRows: 1,
          widths: ['10%', '12.5%', '10%', '67%'],
          body: [
            [
              {
                text: 'No.',
                border: [false, false, false, false],
                margin: [0, 1, 0, 0],
                alignment: 'left'
              },
              {
                text: 'Qty',
                border: [false, false, false, false],
                margin: [0, 1, 0, 0],
                alignment: 'left'
              },
              {
                text: 'UoM',
                border: [false, false, false, false],
                margin: [0, 1, 0, 0],
                alignment: 'left'
              },
              {
                text: 'Item Description',
                border: [false, false, false, false],
                margin: [0, 1, 0, 0],
                alignment: 'center'
              }
            ],
            ...additionalPages
          ]
        }
      }
    },

    PDFVoucherRemarks () {
      // const limitter = 200

      const remarks = []
      const characters = String(this.pdfVoucher.remarks)

      // breakdown to array, to auto break by characters
      for (let character = 0; character < characters.length; character++) {
        remarks.push(characters.charAt(character))
      }

      return {
        margin: [0, 4, 0, 0],
        layout: this.layouts.borderSize(0.5),
        table: {
          headerRows: 1,
          widths: ['*'],
          heights: row => {
            if ([1, 3].includes(row)) {
              return 30
            }
            if ([5].includes(row)) {
              return 40
            }
          },
          body: [
            [
              {
                text: 'Customer:',
                border: [false, false, false, false],
                bold: true
              }
            ],
            [
              {
                text: this.pdfVoucher.delivery.customer ? `${this.pdfVoucher.delivery.customer}, ${this.pdfVoucher.delivery.mobile_number || ''}` : '',
                border: [false, false, false, false],
                margin: [0, 1, 0, 0],
                alignment: 'left',
                bold: false
              }
            ],
            [
              {
                text: 'Delivery Address:',
                border: [false, false, false, false],
                bold: true
              }
            ],
            [
              {
                text: this.pdfVoucher.delivery.address,
                border: [false, false, false, false],
                margin: [0, 1, 0, 0],
                alignment: 'left',
                bold: false
              }
            ],
            [
              {
                text: 'Remarks:',
                border: [false, false, false, false],
                bold: true
              }
            ],
            [
              {
                text: remarks,
                border: [false, false, false, false],
                margin: [0, 1, 0, 0],
                alignment: 'left',
                bold: false
              }
            ]
          ]
        }
      }
    },

    PDFVoucherSignatories () {
      const blankSignature = { text: ' ' }

      const preparedBy = this.pdfVoucher.approvals.find(
        approver => Number(approver.stage) === 3
      )

      const PreparedBySignature = preparedBy ? this.PDFVoucherSignatory(
        'Prepared by:',
        preparedBy.approver_name || this.$store.getters['auth/user'].name,
        this.dateTimeShortFormatter(preparedBy?.status_at || Date.now())?.toUpperCase(),
        false
      ) : blankSignature

      const approvedBy = this.pdfVoucher.approvals.find(
        approver => Number(approver.stage) === 2
      )

      const ApprovedBySignature = approvedBy ? this.PDFVoucherSignatory(
        'Approved by:',
        approvedBy.approver_name,
        this.dateTimeShortFormatter(approvedBy.status_at)?.toUpperCase(),
        false
      ) : blankSignature

      // const PostedBySignature = this.PDFVoucherSignatory(
      //   'Posted by:',
      //   ' ',
      //   '',
      //   true
      // )

      const ReceivedBySignature = this.PDFVoucherSignatory(
        'Received by:',
        ' ',
        'Signature Over Printed Name',
        true, 1.5
      )

      // const ReceivedBySignature = this.PDFVoucherSignatory(
      //   'Received by:',
      //   ' ',
      //   'Signature Over Name',
      //   true
      // )

      return {
        margin: [0, 5, 0, 0],
        // layout: 'noBorders',
        layout: this.layouts.borderSize(0.5),
        table: {
          widths: ['29.5%', '29.5%', '41%'],
          body: [
            [
              PreparedBySignature,
              ApprovedBySignature,
              // PostedBySignature,
              // ReceivedBySignature
              ReceivedBySignature
            ]
          ]
        }
      }
    },

    PDFVoucherReceivedBy () {
      return {
        margin: [5, 1, 3, 1],
        layout: 'noBorders',
        // border: [false, false, false, false],
        table: {
          widths: ['37%', '63%'],
          body: [
            [
              {
                margin: [0, 0],
                layout: this.layouts.recievedBy(0.1),
                // border: [false, false, false, false],
                table: {
                  widths: ['35%', '65%'],
                  body: [
                    [
                      {
                        text: 'Checked By:',
                        border: [false, false, false, false],
                        bold: false
                      },
                      {
                        text: '',
                        border: [false, false, false, true],
                        bold: true
                      }
                    ]
                  ]
                }
              },
              {
                margin: [0, 0],
                layout: this.layouts.recievedBy(0.1),
                // border: [false, false, false, false],
                table: {
                  widths: ['35%', '65%'],
                  body: [
                    [
                      {
                        text: 'Receiver Remarks:',
                        margin: [0, 0],
                        border: [false, false, false, false],
                        bold: false
                      },
                      {
                        text: '',
                        margin: [0, 0],
                        border: [false, false, false, true],
                        bold: true
                      }
                    ]
                  ]
                }
              }
            ]
          ]
        }
      }
    },

    PDFVoucherSignatory (type, name, dateTime, hasLine = true, isReceiver = 0) {
      return {
        margin: [0, 1, 0, 0],
        border: [false, false, false, false],
        stack: [
          {
            text: type,
            margin: [0, 0, 0, 5],
            bold: false
          },
          {
            text: name,
            margin: [0, 0, 0, 3],
            alignment: 'left',
            bold: true
          },
          hasLine ? {
            canvas: [
              {
                type: 'line',
                lineHeight: 1,
                lineWidth: 1,
                color: 'black',
                x1: 0,
                y1: -1,
                x2: isReceiver > 0 ? (140 * isReceiver) + 15 : 138,
                y2: -1
              }
            ]
          } : '',
          {
            text: dateTime,
            alignment: hasLine ? 'center' : 'left',
            fontSize: 10,
            bold: false
          },
          hasLine ? {
            text: 'Date and Time',
            alignment: 'center',
            fontSize: 10,
            bold: false
          } : ''
        ]
      }
    }

  }
}
